import React from 'react';
import HomePage from './pages';
import './App.css';

function App() {
  return (
    <HomePage />
  );
}

export default App;
